<template>
  <v-dialog
    v-model="emailingStore.recipientsDialog"
    scrollable
    persistent
    max-width="600px"
  >
    <v-card class="recipients-dialog">
      <v-card-title>
        <div>
          <span class="capitalize">Recipients</span>
        </div>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid, passes }">
        <v-card-text class="pt-5">
          <v-container>
            <ERow>
              <v-form style="width: 100%">
                <!-- CAMERAS -->
                <ECol cols="12" class="pa-0">
                  <Autocomplete
                    v-model="cameras"
                    item-value="id"
                    item-text="name"
                    label="Cameras"
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    :list-items="camerasItems"
                    :rules="{ required: isFormEmpty }"
                    resource="cameras"
                  >
                    <template #item="{ item, on, attrs }">
                      <v-list-item two-line dense v-bind="attrs" v-on="on">
                        <v-list-item-content class="py-1">
                          <v-list-item-title>
                            <span class="primary--text">{{ item.name }}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle>{{
                            item.exid
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template #selection="{ attrs, select, selected, item }">
                      <EmailingRecipientsChip
                        v-bind="attrs"
                        :input-value="selected"
                        :text="item.name"
                        :caption="item.exid"
                        @click="select"
                        @click:close="removeSelectedCamera(item)"
                      />
                    </template>
                  </Autocomplete>
                </ECol>
                <!-- PROJECTS -->
                <ECol cols="12" class="pa-0">
                  <Autocomplete
                    v-model="projects"
                    resource="projects"
                    item-value="id"
                    item-text="name"
                    label="Projects"
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    :list-items="projectsItems"
                    :rules="{ required: isFormEmpty }"
                  >
                    <template #item="{ item, on, attrs }">
                      <v-list-item two-line dense v-bind="attrs" v-on="on">
                        <v-list-item-content class="py-1">
                          <v-list-item-title>
                            <span class="primary--text">
                              {{ item.name }}
                            </span>
                          </v-list-item-title>
                          <v-list-item-subtitle>{{
                            item.exid
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template #selection="{ attrs, select, selected, item }">
                      <EmailingRecipientsChip
                        v-bind="attrs"
                        :input-value="selected"
                        :text="item.name"
                        :caption="item.exid"
                        @click="select"
                        @click:close="removeSelectedProject(item)"
                      />
                    </template>
                  </Autocomplete>
                </ECol>
                <!-- USERS -->
                <ECol cols="12" class="pa-0">
                  <Autocomplete
                    v-model="users"
                    resource="users"
                    item-value="id"
                    item-text="email"
                    label="Users"
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    :list-items="usersItems"
                    :rules="{ required: isFormEmpty }"
                  >
                    <template #item="{ item, on, attrs }">
                      <v-list-item two-line dense v-bind="attrs" v-on="on">
                        <v-list-item-content class="py-1">
                          <v-list-item-title>
                            <span class="primary--text"
                              >{{ item.fullname }}
                            </span>
                          </v-list-item-title>
                          <v-list-item-subtitle>{{
                            item.email
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template #selection="{ attrs, select, selected, item }">
                      <EmailingRecipientsChip
                        v-bind="attrs"
                        :input-value="selected"
                        :text="item.fullname"
                        :caption="item.email"
                        @click="select"
                        @click:close="removeSelectedUser(item)"
                      />
                    </template>
                  </Autocomplete>
                </ECol>
              </v-form>
            </ERow>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="emailingStore.recipientsDialog = false"
            >Close</v-btn
          >
          <v-btn
            color="primary"
            text
            :disabled="invalid"
            @click="passes(onSave)"
            >Save</v-btn
          >
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import Autocomplete from "@evercam/shared/components/Autocomplete"
import EmailingRecipientsChip from "@/components/emailing/EmailingRecipientsChip"
import { mapStores } from "pinia"
import { useEmailingStore } from "@/stores/emailing"

export default {
  name: "EmailingRecipientsDialog",
  components: {
    Autocomplete,
    EmailingRecipientsChip,
  },
  data() {
    return {
      usersItems: [],
      camerasItems: [],
      projectsItems: [],
      projects: [],
      cameras: [],
      users: [],
    }
  },
  computed: {
    ...mapStores(useEmailingStore),
    isFormEmpty() {
      return !(this.cameras.length || this.projects.length || this.users.length)
    },
  },
  watch: {
    "emailingStore.recipientsDialog"(value) {
      if (value) {
        this.users = this.emailingStore.selectedUsers
        this.cameras = this.emailingStore.selectedCameras
        this.projects = this.emailingStore.selectedProjects
      }
    },
  },
  mounted() {
    this.usersItems = this.users
    this.camerasItems = this.cameras
    this.projectsItems = this.projects
  },
  methods: {
    removeSelectedUser(item) {
      this.users = this.users.filter((el) => el.id !== item.id)
    },
    removeSelectedCamera(item) {
      this.cameras = this.cameras?.filter((el) => el.id !== item.id)
    },
    removeSelectedProject(item) {
      this.projects = this.projects.filter((el) => el.id !== item.id)
    },
    onSave() {
      this.emailingStore.selectedUsers = this.users
      this.emailingStore.selectedCameras = this.cameras
      this.emailingStore.selectedProjects = this.projects
      this.emailingStore.recipients = {
        users: this.emailingStore.selectedUsers.map((u) => u.id),
        cameras: this.emailingStore.selectedCameras.map((c) => c.exid),
        projects: this.emailingStore.selectedProjects.map((p) => p.exid),
      }
      this.emailingStore.recipientsDialog = false
    },
  },
}
</script>

<style>
.capitalize {
  text-transform: capitalize;
}
.recipients-dialog .v-chip__close.v-icon.v-icon.v-icon--link {
  color: #5e5e5e !important;
}
</style>
