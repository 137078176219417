<template>
  <quillEditor
    v-bind="$attrs"
    class="ql-custom-editor"
    :class="{
      'ql-custom-editor__light': light,
      'ql-custom-editor__active': isActive,
    }"
    v-on="$listeners"
    @focus="isActive = true"
    @blur="isActive = false"
  ></quillEditor>
</template>

<script>
import Quill from "quill"
import { quillEditor } from "vue-quill-editor"
import { IndentStyle } from "@/components/quillEditor/IndentStyle"
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"

export default {
  name: "QuillEditor",
  components: {
    quillEditor,
  },
  props: {
    light: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  beforeMount() {
    const AlignClass = Quill.import("attributors/class/align")
    const AlignStyle = Quill.import("attributors/style/align")
    const SizeClass = Quill.import("attributors/class/size")
    const SizeStyle = Quill.import("attributors/style/size")
    Quill.register(AlignClass, true)
    Quill.register(AlignStyle, true)
    Quill.register(SizeClass, true)
    Quill.register(SizeStyle, true)
    Quill.register(IndentStyle, true)
  },
  mounted() {
    this.addToolbarTitles()
  },
  methods: {
    addToolbarTitles() {
      Array.from(
        document.querySelectorAll(".ql-formats > button, .ql-formats > select")
      ).forEach((el) => {
        const title = el.className.split("-").slice(1).join(" ")
        el.title = title
        el.parentNode.title = title
      })
    },
  },
}
</script>

<style lang="scss">
$border-color: #9e9e9e;
$border-color-active: rgb(25, 118, 210);
$transition-duration: 0.2s;

.ql-custom-editor {
  &__light {
    .ql-toolbar.ql-snow {
      display: flex;
      flex-wrap: wrap;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-color: $border-color;

      .ql-formats {
        display: flex;
        margin-right: 0;

        .ql-size,
        .ql-font,
        .ql-header:not(.ql-picker),
        .ql-font,
        .ql-direction,
        .ql-video,
        .ql-image,
        .ql-clean,
        .ql-code-block,
        .ql-script {
          display: none !important;
        }

        svg {
          pointer-events: none;
        }
      }
    }

    .ql-tooltip {
      z-index: 1 !important;
      left: 0 !important;
    }

    .ql-container {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-color: $border-color;

      .ql-editor {
        min-height: 130px;
      }
    }
  }

  &__active {
    .ql-toolbar {
      border-top-width: 2px;
    }
    .ql-container {
      border-bottom-width: 2px;
    }
    .ql-toolbar,
    .ql-container {
      border-color: $border-color-active !important;
      border-left-width: 2px;
      border-right-width: 2px;
    }
  }
}
</style>
