<template>
  <v-chip
    v-bind="$attrs"
    :close="deletable"
    small
    class="ma-1"
    v-on="$listeners"
  >
    <span class="primary--text">{{ text }}</span>
    &nbsp;
    <span class="grey--text caption text--darken-2">
      {{ caption }}
    </span>
  </v-chip>
</template>

<script>
export default {
  name: "EmailingRecipientsChip",
  props: {
    text: {
      type: String,
      default: "",
    },
    caption: {
      type: String,
      default: "",
    },
    deletable: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
